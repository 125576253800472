import {
  FaInstagramSquare,
  FaWhatsappSquare,
  FaPhoneSquareAlt,
  FaEnvelope,
  FaTiktok,
} from "react-icons/fa";

export const navLinks = [
  {
    id: "главное-english-with-anara",
    title: "Главное",
  },
  {
    id: "анара-учитель-английского-языка",
    title: "Обо Mне",
  },
  {
    id: "отзывы-english-with-anara",
    title: "Отзывы",
  },
  {
    id: "курс-английского-english-with-anara",
    title: "О Курсе",
  },
  {
    id: "контакты-english-with-anara",
    title: "Контакты",
  },
];

export const feedbacks = [
  {
    id: "1",
    title: "мой ребёнок заговорил на английском",
    content:
      "Добрый день. У нас сегодня был первый урок. Нам очень понравилось, начиная с новых СЛОВ И Все записи в течение урока было замечательно. Мне важно чтобы мой ребёнок заговорил на английском. И уверена, мы правильно попали к вам. Спасибо!",
    user: {
      name: "мама Адильжана, 10 лет",
    },
  },
  {
    id: "2",
    title: "Лучший учитель английского языка",
    content:
      "Добрый день! Сегодня месяц как мой ребёнок занимается с вами. Потихоньку, появляется у него интерес иностранному языку и он хорошо отзывается о вас. А нам как родителю это в радость.",
    user: {
      name: "мама Айгерима, 9 лет",
    },
  },
  {
    id: "3",
    title: "нужен английский язык",
    content:
      "Мен баска курстарга катысып кордим бирак сиз сиякты ешким жаксы уйретпейди екен. Сейчас у меня есть цель которой я хочу добиться для этого нужен английский язык и мне нужна ваша помощь. ",
    user: {
      name: "Жансая, 21 год",
    },
  },
  {
    id: "4",
    title:
      "Анара превосходный педагог кто заинтересован в обучении английского языка.",
    content:
      "Анара превосходный педагог. С большой ответственностью относится к работе и материал подобран под уровень знания детей. Ее уроки были увлекательными и интерактивными. Она очень терпелива и с уважением относится к детям. Наши дети в восторге от неё, с нетерпением ждет следующего урока. Я очень рекомендую ее всем, кто заинтересован в обучении английского языка. ",
    user: {
      name: "мама Жана (10 лет) и Алана (8 лет)",
    },
  },

  // {
  //   id: "5",
  //   title:
  //     " английском язык, балалар қазір ағылшын тілінде емін еркін сөйлейді",
  //   content:
  //     "Анара, Сизге алғысымыз шексіз, балалардың ағылшын тілі сабағында орасан зор үлес қосқан еңбегіңіз үшін Сизге үлкен рахмет , Сіздің курсыңызды бастамай тұрып алфавитті толық білмейтініміз рас, Сіздің адал еңбегіңіздің арқасында балалар қазір ағылшын тілінде емін еркін сөйлейді, сыныптарында озаттары атанып басқа оқушыларға үлгі тұтарлык деңгейге жеткен Балаларым да Сіз секілді инабатты мұғалімді бірден ұнатып, өзініңіз өткізген сабақтарыңызды унемi асыға күткен және де алі асыға кутуде. Отбасыңызға амандық тілейміз, балаларыңыз тек қуанта берсiн, озат оқушыларыңыз көп болсын",
  //   user: {
  //     name: "Student5",
  //
  //   },
  // },
  {
    id: "6",
    title: "мой ребёнок заговорил на английском",
    content:
      "Добрый день! Хочу выразить благодарность за ваш труд. Еженедельно пишете мне и комментируете как вел себя мой ребёнок. Очень нравится, как вы поддерживаете тесный контакт с нами. Ребёнок доволен уроками, игры в виде заданий очень хорошо залетает.",
    user: {
      name: "мама Нуриддина, 9 лет",
    },
  },
];

export const features = [
  {
    id: "1",
    content:
      " Это один из современных форматов обучения.  Преимущества такого подхода: выбор удобного для вас времени,   не выходя из дома и экономия времени на дорогу. ",
    img: "https://bridge.edu/tefl/blog/wp-content/uploads/2022/01/Structuring-Online-ESL-Lesson-Plans-blog-image.png",
    title: "Уроки проходят онлайн ",
    link: null,
  },
  {
    id: "2",
    content:
      "Для эффективного изучения английского языка мы подкрепляем теорию практикой: употребляя новые слова в речи, читая изученные слова в контексте, слушая в аудио и видео. Таким способом будет проще запомнить новую лексику. Теория должна подкрепляться значительным объемом практики. ",
    img: "https://cdn2.iconfinder.com/data/icons/iconustration-education-color/96/basic-education-listening-speaking-writing-reading-512.png",
    title: "Теория без практики — ничто.",
    link: null,
  },

  {
    id: "3",
    content:
      "Мы будем использовать увлекательные цифровые материалы в соответствии с уровнем знания и потребности ученика. Используя онлайн-ресурсы, такие как Online ESL Games ",
    img: "./interactive-game.png",
    title: "Увлекательные и интерактивные уроки.",
    link: "https://www.gamestolearnenglish.com/",
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "страны имеют английский в качестве официального языка",
    value: "59",
  },
  {
    id: "stats-2",
    title: "страны говорят на английском языке",
    value: "118",
  },
  {
    id: "stats-3",
    title: "носители английского языка",
    value: "378M+",
  },
  {
    id: "stats-4",
    title: "людей второй язык считается английский",
    value: "743M+",
  },

  {
    id: "stats-6",
    title: "информации, хранящейся в компьютерах мира, на английском языке",
    value: "80%",
  },

  {
    id: "stats-7",
    title:
      "Английский язык является основным для большей части международного образования.",
    value: "⭐️",
  },
];

export const socialMedia = [
  {
    id: "instagram",
    icon: FaInstagramSquare,
    link: "https://www.instagram.com/anara_2621 ",
  },
  {
    id: "tiktok",
    icon: FaTiktok,
    link: "https://www.tiktok.com/@anara_2621",
  },
  {
    id: "whatsapp",
    icon: FaWhatsappSquare,
    link: "https://wa.me/77477027055",
  },
  {
    id: "phone",
    icon: FaPhoneSquareAlt,
    link: "tel:+YOURNUMBERHERE",
  },
  {
    id: "email",
    icon: FaEnvelope,
    link: "mailto:anekasina@mail.ru",
  },
];
